<template>
  <div class="cs-container" style="background: white">
    <div
      class="d-print-none mb-2 flex items-center justify-end"
      style="margin-right: 80px"
    >
      <b-button variant="primary" @click="handlePrint">In Phiếu</b-button>
    </div>

    <header-form></header-form>

    <div class="d-flex align-items-center justify-content-center mx-auto w-90">
      <!-- <img class="cs-img col-6" src="@/assets/images/pages/AGTC-logo.png" /> -->
      <h6 class="text-center title-color" style="font-weight: 600">
        PHIẾU ĐỒNG THUẬN THỰC HIỆN XÉT NGHIỆM GEN
      </h6>
      <span class="close-icon d-print-none" @click="backToDetail">&times;</span>
    </div>

    <div id="printOrderForm">
      <b-row>
        <b-col>
          <div>
            <div class="cs-flex">
              <div class="cs-title">PHẦN 1: THÔNG TIN NGƯỜI LÀM XÉT NGHIỆM</div>
            </div>
            <div class="cs-flex cs-border-b">
              <div class="col-7 p-0">
                <span class="cs-label">Họ tên: </span>
                <span class="cs-content" v-html="form.full_name"></span>
              </div>
              <div class="col-5 p-0">
                <span class="cs-label">Ngày sinh: </span>
                <span class="cs-content" v-html="formatDMY(form.birthday)"></span>
              </div>
            </div>
            <div class="cs-flex cs-border-b">
              <div class="col-7 p-0">
                <span class="cs-label">Giới tính: </span>
                <div class="d-inline-flex cs-content ml-1">
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isMale = form.gender === 1)"
                  >
                    <input
                      v-model="isMale"
                      id="male"
                      name="male"
                      type="checkbox"
                    />
                    <label>Nam</label>
                  </div>
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isFemale = form.gender === 2)"
                  >
                    <input
                      v-model="isFemale"
                      id="female"
                      name="female"
                      type="checkbox"
                    />
                    <label>Nữ</label>
                  </div>
                </div>
              </div>
              <div class="col-5 p-0">
                <span class="cs-label">Điện thoại: </span>
                <span class="cs-content" v-html="form.phone_number"></span>
              </div>
            </div>
            <div class="cs-flex cs-border-b">
              <div class="col-7 p-0">
                <div class="cs-flex p-0">
                  <div class="col-7 p-0">
                    <span class="cs-label">Địa chỉ liên hệ: </span>
                    <span class="cs-content" v-html="form.address"></span>
                  </div>
                  <div class="col-5 p-0">
                    <span class="cs-label">Email: </span>
                    <span class="cs-content" v-html="form.email"></span>
                  </div>
                </div>
              </div>
              <div class="col-5 p-0">
                <span class="cs-label">Tình trạng hôn nhân/gia đình: </span>
                <span class="cs-content" v-html="form.marriage_status"></span>
              </div>
            </div>
            <div class="cs-flex cs-border-b">
              <div class="col-7 p-0">
                <span class="cs-label">Phòng khám: </span>
                <span class="cs-content" v-html="form.clinic"></span>
              </div>
              <div class="col-5 p-0">
                <span class="cs-label">Bác sĩ chỉ định: </span>
                <span class="cs-content" v-html="form.doctor_name"></span>
              </div>
            </div>
            <div class="cs-flex cs-border-b">
              <div class="col-7 p-0">
                <span class="cs-label">Ngày thu mẫu: </span>
                <span class="cs-content" v-html="form.sample_taken_at"></span>
              </div>
              <div class="col-5 p-0">
                <span class="cs-label">Nơi thu mẫu: </span>
                <span class="cs-content" v-html="form.sample_taken_place"></span>
              </div>
            </div>
          </div>

          <div>
            <div class="cs-flex">
              <span class="cs-title">PHẦN 2: XÉT NGHIỆM YÊU CẦU </span>
            </div>
            <div class="cs-flex">
              <div class="cs-checkbox">
                Tầm soát sớm 5 loại ung thư phổ biến nhất (Vú - Phổi - Gan - Đại
                trực tràng - Dạ dày) dựa trên DNA của tế bào ung thư phóng thích
                vào trong máu (ctDNA)
              </div>
            </div>
          </div>

          <div>
            <div class="cs-flex">
              <span class="cs-title"
                >PHẦN 3: THÔNG TIN CÁ NHÂN
                <span class="text-normal"
                  >(cần thiết nhằm hỗ trợ phân tích kết quả)</span
                >
              </span>
            </div>

            <div class="cs-flex py-0">
              <span class="cs-label cs-font-bold text-red"
                >A. TIỀN CĂN UNG THƯ/U LÀNH CỦA BẢN THÂN (thông tin bắt
                buộc)</span
              >
            </div>
            <div class="cs-flex cs-border-b">
              <div class="col-6 p-0">
                <span class="cs-label">Đã mắc/chẩn đoán ung thư:</span>
                <div class="d-inline-flex cs-content ml-1">
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isMale = form.isHaveCancer === 1)"
                  >
                    <input
                      v-model="isMale"
                      id="male"
                      name="male"
                      type="checkbox"
                    />
                    <label>Không</label>
                  </div>
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isFemale = form.isHaveCancer === 2)"
                  >
                    <input
                      v-model="isFemale"
                      id="female"
                      name="female"
                      type="checkbox"
                    />
                    <label>Có</label>
                  </div>
                </div>
              </div>
              <div class="col-6 p-0">
                <span class="cs-label"> Bệnh ung thư cụ thể: </span>
                <span class="cs-content" v-html="form.cancer_type"></span>
              </div>
            </div>
            <div class="cs-flex cs-border-b">
              <div class="col-6 p-0">
                <span class="cs-label">Có u lành tính:</span>
                <div class="d-inline-flex cs-content ml-1">
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isMale = form.isHaveBenignTumor === 1)"
                  >
                    <input
                      v-model="isMale"
                      id="male"
                      name="male"
                      type="checkbox"
                    />
                    <label>Không</label>
                  </div>
                  <div
                    class="mr-1 cs-checkbox"
                    :set="(isFemale = form.isHaveBenignTumor === 2)"
                  >
                    <input
                      v-model="isFemale"
                      id="female"
                      name="female"
                      type="checkbox"
                    />
                    <label>Có</label>
                  </div>
                </div>
              </div>
              <div class="col-6 p-0">
                <span class="cs-label"> Cơ quan cụ thể: </span>
                <span class="cs-content" v-html="form.benignTumorPosition"></span>
              </div>
            </div>
            <div class="cs-flex mb-1 cs-border-b">
              <span class="cs-label text-red"
                >Đối với u lành tính cần gởi kèm thông tin chi tiết khối u, thời
                gian phát hiện và điều trị (nếu có), kết quả cận lâm sàng (siêu
                âm, nhũ ảnh, X - Quang, nội soi, sinh thiết FNA,...). Cụ thể: u
                tuyến giáp (TIRAD 3,4) gởi kèm KQ siêu âm và/hoặc FNA hoặc u vú
                (BIRAD 3,4) gởi kèm KQ siêu âm/nhũ ảnh và/hoặc FNA</span
              >
            </div>

            <div class="cs-flex py-0">
              <span class="cs-label cs-font-bold text-blue"
                >B. THÓI QUEN - NGUY CƠ KHÁC</span
              >
            </div>
            <div class="cs-width-full cs-flex">
              <div class="container-border cs-width-full">
                <div class="d-flex">
                  <div class="col-4 p-0 cs-border-r">
                    <div class="cs-flex">
                      <li>
                        <span class="cs-label">Hút thuốc lá:</span>
                        <div class="d-inline-flex cs-content ml-1">
                          <div
                            class="mr-1 cs-checkbox text-blue"
                            :set="(isSmoking = form.isSmoking === 1)"
                          >
                            <input
                              :class="{uncheck:!isSmoking}"
                              :checked="isSmoking"
                              id="isSmoking"
                              name="isSmoking"
                              type="checkbox"
                            />
                            <label>Có</label>
                          </div>
                          <div
                            class="mr-1 cs-checkbox text-red"
                            :set="(isFemale = form.isSmoking === 2)"
                          >
                            <input
                              :class="{uncheck:!isFemale}"
                              :checked="isFemale"
                              id="female"
                              name="female"
                              type="checkbox"
                            />
                            <label>Không</label>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                  <div class="col-8 p-0 cs-border-r">
                    <div class="cs-flex">
                      <li>
                        <span class="cs-label">Uống rượu:</span>
                        <div class="d-inline-flex cs-content ml-1">
                          <div
                            class="mr-1 cs-checkbox text-blue"
                            :set="(isMale = form.isDrinking === 1)"
                          >
                            <input
                              :class="{uncheck:!isMale}"
                              v-model="isMale"
                              id="male"
                              name="male"
                              type="checkbox"
                            />
                            <label>Có</label>
                          </div>
                          <div
                            class="mr-1 cs-checkbox text-red"
                            :set="(isFemale = form.isDrinking === 2)"
                          >
                            <input
                              :class="{uncheck:!isFemale}"
                              v-model="isFemale"
                              id="female"
                              name="female"
                              type="checkbox"
                            />
                            <label>Không</label>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
                <div class="d-flex cs-border-t">
                  <div class="col-12 p-0 cs-border-r">
                    <div class="cs-flex">
                      <li>
                        <span class="cs-label">Viêm gan siêu vi B,C:</span>
                        <div class="d-inline-flex cs-content ml-1">
                          <div
                            class="mr-1 cs-checkbox text-blue"
                            :set="(isMale = form.isHepatitis === 1)"
                          >
                            <input
                              :class="{uncheck:!isMale}"
                              v-model="isMale"
                              id="male"
                              name="male"
                              type="checkbox"
                            />
                            <label>Có</label>
                          </div>
                          <div
                            class="mr-1 cs-checkbox text-red"
                            :set="(isFemale = form.isHepatitis === 2)"
                          >
                            <input
                            :class="{uncheck:!isFemale}"
                              v-model="isFemale"
                              id="female"
                              name="female"
                              type="checkbox"
                            />
                            <label>Không</label>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
                <div class="d-flex cs-border-t">
                  <div class="col-12 p-0 cs-border-r">
                    <div class="cs-flex pb-0">
                      <li>
                        <span class="cs-label"
                          >Mang gen di truyền ung thư:</span
                        >
                        <div class="d-inline-flex cs-content ml-1">
                          <div
                            class="mr-1 cs-checkbox text-blue"
                            :set="(isMale = form.is_carry_cancer_genes === 1)"
                          >
                            <input
                            :class="{uncheck:!isMale}"
                              v-model="isMale"
                              id="male"
                              name="male"
                              type="checkbox"
                            />
                            <label>Có</label>
                          </div>
                          <div
                            class="mr-1 cs-checkbox text-red"
                            :set="(isFemale = form.is_carry_cancer_genes === 2)"
                          >
                            <input
                            :class="{uncheck:!isFemale}"
                              v-model="isFemale"
                              id="female"
                              name="female"
                              type="checkbox"
                            />
                            <label>Không</label>
                          </div>
                          <div
                            class="mr-1 cs-checkbox text-yellow"
                            :set="(unknown = form.is_carry_cancer_genes === 3)"
                          >
                            <input
                            :class="{uncheck:!unknown}"
                              v-model="unknown"
                              id="female"
                              name="female"
                              type="checkbox"
                            />
                            <label>Chưa biết</label>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div class="cs-flex pt-0" style="font-size: 14px">
                      (Xét nghiệm ung thư di truyền được thực hiện 1 lần trong
                      đời có thể giúp biết được bản thân có mang gen di truyền
                      ung thư hay không)
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="cs-flex py-0">
              <span class="cs-label cs-font-bold text-blue"
                >C. CÁC DẤU HIỆU CỦA UNG THƯ</span
              >
            </div>
            <div class="cs-width-full cs-flex">
              <div class="container-border cs-width-full">
                <div class="d-flex">
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex">
                      <li class="w-full">
                        <span class="cs-label d-inline-flex w-70"
                          >Sụt cân nhanh (từ 6kg trở lên trong 6 tháng mà không
                          rõ nguyên nhân)</span
                        >
                        <div class="d-inline-flex cs-content ml-1">
                          <div
                            class="mr-1 cs-checkbox text-blue"
                            :set="(isMale = form.isLosingWeight === 1)"
                          >
                            <input
                            :class="{uncheck:!isMale}"
                              v-model="isMale"
                              id="male"
                              name="male"
                              type="checkbox"
                            />
                            <label>Có</label>
                          </div>
                          <div
                            class="mr-1 cs-checkbox text-red"
                            :set="(isFemale = form.isLosingWeight === 2)"
                          >
                            <input
                            :class="{uncheck:!isFemale}"
                              v-model="isFemale"
                              id="female"
                              name="female"
                              type="checkbox"
                            />
                            <label>Không</label>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex">
                      <li class="w-full">
                        <span class="cs-label d-inline-flex"
                          >Ho kéo dài > 3 tuần, uống thuốc không đỡ</span
                        >
                        <div class="d-inline-flex cs-content ml-1">
                          <div
                            class="mr-1 cs-checkbox text-blue"
                            :set="(isMale = form.isCough === 1)"
                          >
                            <input
                            :class="{uncheck:!isMale}"
                              v-model="isMale"
                              id="male"
                              name="male"
                              type="checkbox"
                            />
                            <label>Có</label>
                          </div>
                          <div
                            class="mr-1 cs-checkbox text-red"
                            :set="(isFemale = form.isCough === 2)"
                          >
                            <input
                            :class="{uncheck:!isFemale}"
                              v-model="isFemale"
                              id="female"
                              name="female"
                              type="checkbox"
                            />
                            <label>Không</label>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div class="cs-flex cs-border-t">
                      <li class="w-full">
                        <span class="cs-label d-inline-flex"
                          >Chảy máu, chảy dịch bất thường ở núm vú</span
                        >
                        <div class="d-inline-flex cs-content ml-1">
                          <div
                            class="mr-1 cs-checkbox text-blue"
                            :set="(isMale = form.is_fluid_discharge === 1)"
                          >
                            <input
                            :class="{uncheck:!isMale}"
                              v-model="isMale"
                              id="male"
                              name="male"
                              type="checkbox"
                            />
                            <label>Có</label>
                          </div>
                          <div
                            class="mr-1 cs-checkbox text-red"
                            :set="(isFemale = form.is_fluid_discharge === 2)"
                          >
                            <input
                            :class="{uncheck:!isFemale}"
                              v-model="isFemale"
                              id="female"
                              name="female"
                              type="checkbox"
                            />
                            <label>Không</label>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
                <div class="d-flex cs-border-t">
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex">
                      <li class="w-full">
                        <span class="cs-label d-inline-flex w-70"
                          >Đau bụng hạ sườn phải và thượng vị kéo dài, uống
                          thuốc không đỡ</span
                        >
                        <div class="d-inline-flex cs-content ml-1">
                          <div
                            class="mr-1 cs-checkbox text-blue"
                            :set="(isMale = form.is_stomach_ache === 1)"
                          >
                            <input
                            :class="{uncheck:!isMale}"
                              v-model="isMale"
                              id="male"
                              name="male"
                              type="checkbox"
                            />
                            <label>Có</label>
                          </div>
                          <div
                            class="mr-1 cs-checkbox text-red"
                            :set="(isFemale = form.is_stomach_ache === 2)"
                          >
                            <input
                            :class="{uncheck:!isFemale}"
                              v-model="isFemale"
                              id="female"
                              name="female"
                              type="checkbox"
                            />
                            <label>Không</label>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex">
                      <li class="d-flex w-full">
                        <span class="cs-label d-inline-flex w-70"
                          >Rối loạn đi cầu thường xuyên: tiêu chảy - táo bón -
                          đi cầu ra máu...</span
                        >
                        <div class="d-inline-flex cs-content ml-1">
                          <div
                            class="mr-1 cs-checkbox text-blue"
                            :set="(isMale = form.isConstipation === 1)"
                          >
                            <input
                            :class="{uncheck:!isMale}"
                              v-model="isMale"
                              id="male"
                              name="male"
                              type="checkbox"
                            />
                            <label>Có</label>
                          </div>
                          <div
                            class="mr-1 cs-checkbox text-red"
                            :set="(isFemale = form.isConstipation === 2)"
                          >
                            <input
                            :class="{uncheck:!isFemale}"
                              v-model="isFemale"
                              id="female"
                              name="female"
                              type="checkbox"
                            />
                            <label>Không</label>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cs-flex">
              <span class="cs-label cs-font-bold"
                >Nếu có một trong những dấu hiệu trên, anh/chị được khuyến cáo
                thực hiện các xét nghiệm phát hiện và chẩn đoán xác định ung
                thư.</span
              >
            </div>

            <div class="cs-flex py-0">
              <span class="cs-label cs-font-bold text-blue">D. LOẠI TRỪ</span>
            </div>
            <div class="cs-flex">
              <span class="cs-label">Dịch vụ KHÔNG thực hiện trên người:</span>
            </div>
            <ol class="mb-2">
              <li class="col-12 p-0">
                Đã được chẩn đoán mắc ung thư và đang điều trị ung thư trong
                vòng 5 năm (gởi kèm kết quả giải phẫu bệnh)
              </li>
              <li class="col-12 p-0">
                Có dấu hiệu của ung thư (Phần 3 - mục C) và chưa đi khám bệnh
              </li>
              <li class="col-12 p-0">
                Có u/sang thương hình ảnh học gợi ý ung thư (BIRADS ≥ 5, TIRADS
                ≥ 5...)
              </li>
              <li class="col-12 p-0">
                Có u/sang thương đã được ghi nhận gây nhiễu tín hiệu ctDNA: u
                xơ, u sợi vú hoặc tử cung (fibroma, fibroadenoma), polyp đại
                trực tràng và túi mật ≥ 1 cm.
              </li>
              <li class="col-12 p-0">Người đang mang thai</li>
              <li class="col-12 p-0">
                Người có tiền sử ghép tủy hoặc truyền máu trong vòng 3 tháng
              </li>
            </ol>
          </div>

          <div class="html2pdf__page-break" style="margin-top:6rem;">
            <div class="cs-flex">
              <span class="cs-title"
                >PHẦN 4: TIỀN SỬ BỆNH UNG THƯ CỦA GIA ĐÌNH</span
              >
            </div>
            <div class="cs-flex">
              <span class="cs-label"
                >Xin liệt kê các thành viên trong gia đình đã được chẩn đoán ung
                thư (mỗi hàng một thành viên)</span
              >
            </div>
            <div class="cs-width-full cs-flex">
              <div class="container-border cs-width-full">
                <div class="d-flex">
                  <div class="col-4 p-0 cs-border-r">
                    <div class="cs-flex">Mối quan hệ với bệnh nhân</div>
                  </div>
                  <div class="col-4 p-0 cs-border-r">
                    <div class="cs-flex">Vị trí ung thư</div>
                  </div>
                  <div class="col-4 p-0">
                    <div class="cs-flex">Tuổi mắc bệnh</div>
                  </div>
                </div>
                <div v-for="row in form.family_cancer" :key="row.relationship" class="d-flex cs-border-t">
                  <div class="col-4 p-0 cs-border-r">
                    <div class="cs-flex">{{ row.relationship }}
                      <br v-if="!row.relationship" />
                    </div>
                  </div>
                  <div class="col-4 p-0 cs-border-r">
                    <div class="cs-flex">{{ row.cancer_position }}
                      <br v-if="!row.cancer_position" />
                    </div>
                  </div>
                  <div class="col-4 p-0">
                    <div class="cs-flex">{{ row.ageHave }}
                      <br v-if="!row.ageHave" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="cs-flex">
              <span class="cs-title"
                >PHẦN 5: THÔNG TIN CẦN BIẾT VỀ XÉT NGHIỆM</span
              >
            </div>
            <div class="cs-flex">
              <span class="cs-label cs-font-bold text-blue"
                >A. HÌNH THỨC TRẢ KẾT QUẢ</span
              >
            </div>
            <div class="cs-flex">
              <span class="cs-label"
                >Tín hiệu ctDNA của tế bào ung thư trong máu ngoại vi và xác
                suất % dự đoán nguồn gốc của u.</span
              >
            </div>
            <div class="cs-flex">
              <span class="cs-label cs-font-bold text-blue"
                >B. LỢI ÍCH CỦA XÉT NGHIỆM</span
              >
            </div>
            <ul>
              <li class="col-12 p-0">
                Kết quả xét nghiệm sẽ giúp Khách hàng và Bác sĩ có kế hoạch
                phòng ngừa ung thư phù hợp, can thiệp điều trị sớm và chủ động
                nhất có thể.
              </li>
              <li class="col-12 p-0">
                Phát hiệu ung thư từ giai đoạn sớm giúp điều trị hiệu quả.
              </li>
            </ul>
            <div class="cs-flex">
              <span class="cs-label cs-font-bold text-blue">C. GIỚI HẠN</span>
            </div>
            <div class="cs-flex">
              <span class="cs-label cs-font-bold"
                >Tầm soát sớm ung thư: vì dựa trên ctDNA phóng thích từ khối u
                vào máu ngoại vi nên phương pháp sẽ có những giới hạn sau:
              </span>
            </div>
            <ul>
              <li class="col-12 p-0">
                Một kết quả âm tính (chưa có tín hiệu ctDNA) không hoàn toàn
                loại trừ sự hiện diện của ung thư do nằm ngoài phạm vi khảo sát
                (5 loại ung thư phổ biến nhất) hoặc khối u quá nhỏ và nằm ở vị
                trí khó phóng thích ctDNA vào máu.
              </li>
              <li class="col-12 p-0">
                Một kết quả dương tính (có tín hiệu ctDNA) không hoàn toàn khẳng
                định người tham gia xét nghiệm có khối u, vì một số bệnh lí khác
                có thể tạo nên tín hiệu ctDNA "giả". Vì vậy, một kết quả dương
                tính cần được tư vấn bác sĩ ung thư và khẳng định bằng các
                phương pháp chẩn đoán hình ảnh.
              </li>
              <li class="col-12 p-0">
                Trong trường hợp dương tính, việc xác định vị trí khối u sẽ được
                dự báo bằng mô hình máy học dựa trên các đặc tính khác biệt của
                ctDNA và được trả kết quả với xác suất %.
              </li>
            </ul>
            <div class="cs-flex">
              <span class="cs-label cs-font-bold text-blue">D. HỖ TRỢ</span>
            </div>
            <div class="cs-flex">
              <span class="cs-label cs-font-bold"
                >Người có tín hiệu ctDNA dương tính và KHÔNG BỊ CHẨN ĐOÁN MẮC
                UNG THƯ HOẶC CÓ DẤU HIỆU MẮC UNG THƯ TRƯỚC ĐÂY sẽ được hỗ trợ
                chi phí xét nghiệm chẩn đoán phù hợp theo tư vấn (không vượt quá
                mức tối đa cho từng dịch vụ)</span
              >
            </div>
            <div class="cs-flex">
              <span class="cs-label"
                >(*) Chúng tôi luôn tuân thủ Luật HIPAA và Quy tắc bảo mật để
                bảo vệ thông tin y khoa của khách hàng, vui lòng xem thêm tại
                website của chúng tôi cho các chính sách bảo mật này.</span
              >
            </div>
          </div>

          <div class="cs-border-b" style="border-color: #0569C5">
            <div class="cs-flex">
              <span class="cs-title"
                >PHẦN 6: ĐỒNG Ý CỦA NGƯỜI LÀM XÉT NGHIỆM VÀ BÁC SĨ</span
              >
            </div>
            <div class="cs-flex">
              <span class="cs-label"> Tôi, ký tên dưới đây hiểu rằng: </span>
            </div>
            <div class="cs-flex" style="align-items: baseline">
              <div class="col-8 p-0">
                <ul>
                  <li>
                    Tôi đã được cung cấp thông tin về những lợi ích và hạn chế
                    của các xét nghiệm mà tôi yêu cầu được thực hiện. Tôi đã
                    được cung cấp thông tin về độ tin cậy của các kết quả xét
                    nghiệm, và về mức độ liên quan của kết quả xét nghiệm với
                    các bệnh ung thư cụ thể.
                  </li>
                  <li>
                    Nhà cung cấp dịch vụ có thể sử dụng mẫu dư và các thông tin
                    đã loại bỏ danh tính của Khách hàng cho các nghiên cứu khoa
                    học về tầm soát ung thư sớm. Các nghiên cứu này có thể được
                    công bố trên các tạp chí khoa học hoặc sử dụng để phát triển
                    các sản phẩm và dịch vụ. Khách hàng sẽ không được thông báo
                    hoặc trả tiền cho các hoạt động nghiên cứu này.
                  </li>
                </ul>
              </div>
              <div class="col-4 p-0 d-flex flex-col items-center">
                Ngày ký: ....../....../......
                <span class="cs-font-bold">Chữ ký Khách hàng:</span>
              </div>
            </div>
          </div>

          <div class="cs-flex" style="align-items: baseline">
            <span class="cs-label col-8 p-0">
              Tôi đã giải thích đầy đủ về xét nghiệm, bao gồm rủi ro, lợi ích và lựa chọn thay thế cho Khách hàng dựa trên pháp lý và trả lời tất cả các câu hỏi của họ.
            </span>
            <div class="col-4 p-0 d-flex flex-col items-center">
                Ngày ký: ....../....../......
                <span class="cs-font-bold">Chữ ký Bác sĩ:</span><br/><br/>
              </div>
          </div>
          <div class="cs-flex">
            <span class="cs-label text-blue cs-font-bold">
              Hotline tư vấn: 02871018688
            </span>
          </div>
        
        </b-col>
      </b-row>
    </div>
  </div>
</template>
    
<script>
import { DEFAULT_CIPHERS } from "tls";
import { mapState } from "vuex";
import HeaderForm from "@/views/PrintOrderForm/HeaderForm.vue";
import _ from 'lodash'
const DEFAULT_FORM = {
  full_name: "",
  birthday: "",
  address: "",
  phone_number: "",
  email: "",
  marriage_status: "",
  doctor_name: "",
  clinic: "",
  doctor_phone_number: null,
  sample_taken_at: "",
  sample_taken_place: "",
  isHaveCancer: null,
  cancer_type: "",
  isHaveBenignTumor: null,
  benignTumorPosition: "",
  isSmoking: null,
  isDrinking: null,
  isHepatitis: null,
  is_carry_cancer_genes: null,
  isLosingWeight: null,
  isCough: null,
  is_fluid_discharge: null,
  is_stomach_ache: null,
  isConstipation: null,
  family_cancer: [
    {
      relationship: "",
      cancer_position: "",
      ageHave: null
    },
    {
      relationship: "",
      cancer_position: "",
      ageHave: null
    },
    {
      relationship: "",
      cancer_position: "",
      ageHave: null
    },
  ]
};

export default {
  name: "PrintOrderConsentForm",
  components: {
    HeaderForm,
  },
  data() {
    return {
      form: { ...DEFAULT_FORM },
      DEFAULT_FORM,
    };
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders,
    }),
    dateNow() {
      const date = new Date();
      return `Ngày ${date.getDate()} tháng ${
        date.getMonth() + 1
      } năm ${date.getFullYear()}`;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.handleMapData();
    }
  },
  methods: {
    formatDMY (date) {
      return date ? window.moment(date).format('DD/MM/YYYY') : ""
    },
    handleMapData() {
      this.form = _.isEmpty(this.orders.requisitionFormV2) ? { ...DEFAULT_FORM } : this.orders.requisitionFormV2;
    },
    handlePrint() {
      document.title = "Consent-form-for-performing-gene-test";
      window.print();
    },
    backToDetail() {
      document.title = "HODO Partner";
      this.$router.replace({
        name: "orderDetail",
        params: {
          id: this.orders?.viewDetail.id,
        },
        query: {
          step: 1,
        },
      });
    },
    handleFormatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    },
  },
};
</script>
    
<style lang="scss" scoped>
.cs-container {
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
    font-size: 18px;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .w-90 {
    width: 90%;
  }
  .w-80 {
    width: 80%;
  }
  .w-30 {
    width: 30%;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 1px 6px;
  border: 1px solid #5c5c5c;
  background: #0569C5;
  color: white;
  .text-normal {
    color: white;
    text-transform: lowercase;
  }
}

.cs-flex {
  display: flex;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
  .flex-grow-4 {
    flex-grow: 4;
  }
  .flex-grow-2 {
    flex-grow: 2;
  }
}

// .cs-label {
// width: 150px;
// }

.cs-border-b {
  border-bottom: 1px solid #5c5c5c;
}
.cs-border-t {
  border-top: 1px solid #5c5c5c;
}

.cs-border-l {
  border-left: 1px solid #5c5c5c;
}

.cs-border-r {
  border-right: 1px solid #5c5c5c;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  pointer-events: none;
  display: flex;
  align-items: baseline;
  input {
    transform: translateY(3px);
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #0569C5;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}
.w-full {
  width: 100%;
}
.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  color: black;
  font-size: 40px;
  cursor: pointer;
}

.cs-img {
  width: 300px;
}

.title-color {
  font-size: 32px !important;
  color: #0569C5;
}
.text-red {
  color: red;
  * {
    color: red;
  }
  input.uncheck {
    outline: 1px solid red;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
  }
}
.text-blue {
  color: #0569C5;
  * {
    color: #0569C5;
  }
  input.uncheck {
    outline: 1px solid #0569C5;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
  }
}
.text-yellow {
  color: #d6da21;
  * {
    color: #d6da21;
  }
  input.uncheck {
    outline: 1px solid #d6da21;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
  }
}
.w-70 {
  max-width: 80%;
}
@media print {
  body,
  html {
    height: 100vh;
    *  {
      color: black !important;
    }
  }
  .cs-title {
    color: white !important;
    .text-normal {
      color: white !important;
    }
  }

  .hidden-print {
    border: none;
    outline: none;
    background: transparent;
  }

  #BrowserPrintDefaults {
    display: none;
  }

  #printOrderForm {
    max-width: 1000px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .html2pdf__page-break {
    page-break-before: always;
  }

  @page {
    margin: 0;
  }
}
</style>